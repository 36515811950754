body,html{
    padding: 0;
    margin: 0;
    margin-top: 0;
    font-family: 'Montserrat', sans-serif;
    overflow-x: hidden;
    scroll-behavior: smooth;
  }
/* width */
::-webkit-scrollbar {
    width: 8px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
  background:transparent;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #627a5d; 
    border-radius: 10px;
    
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #758175; 
  }